import React, {useContext} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/core/styles";
import images from "../../global/images";
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import NoSsr from '@material-ui/core/NoSsr';
import LanguageIcon from '@material-ui/icons/Translate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Context} from "../../App";
import {useIntl} from "react-intl";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 12px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    right: {
        marginLeft: "auto",
        marginRight: -20,
        marginTop: 5

    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    euLogo: {
        height: '50px',
        align: "right",
    },
    mainLogo: {
        width: '40%',
        maxWidth: '139px',
        height: 'auto',
    }
}));

export default function ReservagoAppBar({}) {
    const classes = useStyles();
    const context = useContext(Context);
    const [languageMenu, setLanguageMenu] = React.useState(null);
    const intl = useIntl(); // intl extracted from useIntl hook

    const handleLanguageIconClick = (event) => {
        setLanguageMenu(event.currentTarget);
    };
    const handleLanguageMenuClose = (event) => {
        debugger
        setLanguageMenu(null);
        if (event.target.lang) {
            context.selectLanguage(event.target.lang);
        }
    };

    const LANGUAGES_LABEL = [{code: 'en', text: 'English'}, {code: 'cs', text: 'Česky'}, , {code: 'de', text: 'Deutsch'}];

    return (
        <AppBar position="fixed" className={classes.root}>
            <Toolbar className={classes.root}>
                <img alt="ReservaGo logo" src={images.logo} className={classes.mainLogo}/>
                <Tooltip title={intl.formatMessage({id: 'chooseLanguage'})} enterDelay={300}>
                    <Button
                        color="inherit"
                        aria-owns={languageMenu ? 'language-menu' : undefined}
                        aria-haspopup="true"
                        onClick={handleLanguageIconClick}
                        data-ga-event-category="header"
                        data-ga-event-action="language"
                    >
                        <LanguageIcon/>
                        <span className={classes.language}>
                {LANGUAGES_LABEL.filter((language) => language.code === context.locale)[0].text}
              </span>
                        <ExpandMoreIcon fontSize="small"/>
                    </Button>
                </Tooltip>
                <NoSsr defer>
                    <Menu
                        id="language-menu"
                        anchorEl={languageMenu}
                        open={Boolean(languageMenu)}
                        onClose={handleLanguageMenuClose}
                    >
                        {LANGUAGES_LABEL.map((language) => (
                            <MenuItem
                                component="a"
                                data-no-link="true"
                                key={language.code}
                                selected={context.locale === language.code}
                                onClick={handleLanguageMenuClose}
                                lang={language.code}
                                hrefLang={language.code}
                            >
                                {language.text}
                            </MenuItem>
                        ))}
                    </Menu>
                </NoSsr>
            </Toolbar>
        </AppBar>
    );
}
