import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ProviderDetails from '../providerDetails';
import Typography from '@material-ui/core/Typography';
import { Grid, List, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core';
import images from '../../global/images';
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForward';
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
      width: '48vw',
      maxWidth: '48vw',
    },
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    minHeight: 'calc(100vh - 85px)',
    justifyContent: 'space-around',
    transition:
      '-ms-transform 850ms cubic-bezier(0.25, 1, 0.5, 1) 0s, -webkit-transform 850ms cubic-bezier(0.25, 1, 0.5, 1) 0s, transform 850ms cubic-bezier(0.25, 1, 0.5, 1) 0s',
  },
  wrapper: {
    margin: '10px',
    minHeight: 'calc(100vh - 85px)',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      height: '200px',
      display: 'block',
      width: '80%',
      margin: '0 auto',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '40px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '150px',
      width: '45%',
    }
  },
  icon: {
    width: '22px',
    height: '22px',
    paddingRight: '10px',
  },
  eu: {
    height: '50px',
    position: 'absolute',
    left: 'calc(50% - 156px)',
    bottom: '-100px',
  },
  serviceName: {
    height: '22px',
    textAlign: 'left',
  },
  filterItem: {
    borderRadius: '25px',
    borderColor: theme.palette.hazard.main,
    borderStyle: 'solid',
    width: '200px',
    height: '50px',
    paddingTop: '0px',
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '40px',
    },
  },
  filterItemMenu: {
    paddingTop: '0px',
  },
  titleWithIcon: {
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '200px',
  },
  closeButton: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    right: '10px',
    zIndex: 1000,
    width: '35px',
    height: '35px',
    color: 'black',
    backgroundColor: 'white',
  },
}))

export default function ProviderList({
  services,
  selectedService,
  listVisibleServices,
  setSelectedService,
  scrolledOn,
  executeScroll,
  updateFeatures,
  hidden,
  setHidden
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const intl = useIntl(); // intl extracted from useIntl hook
  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  }
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const myRef = useRef({});

  const handleMenuItemClick = (event, index) => {
    setSelectedService(index);
    setAnchorEl(null);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const getIcon = service => {
    if (service.icon)
      return service.icon;
    else if (images[service.name])
      return images[service.name];
    else
      return images.all;
  };

  const getServiceDesc = service => {
    return (
      <div className={classes.titleWithIcon}>
        <img className={classes.icon} src={getIcon(service)} alt={`${service.name} icon`} />
        <div className={classes.serviceName}> {service && service.name}</div>
      </div>
    )
  };

  const setRef = (element, provider) => {
    myRef.current[provider.id] = element;
  }

  useEffect(() => {
    if (scrolledOn && myRef.current[scrolledOn.id])
      myRef.current[scrolledOn.id].scrollIntoView(true);
  }, [scrolledOn, hidden]);
  console.log("scrolledOn", scrolledOn);

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <div>
          <Typography variant="h5">{intl.formatMessage({ id: 'providerListSelectedOptions' })}</Typography>
          <Typography variant="body1">
            {listVisibleServices ? listVisibleServices.length : 0} {intl.formatMessage({ id: 'possibilities' })}
          </Typography>
        </div>

        <List component="nav" aria-label="Device settings" className={classes.filterItem}>
          <ListItem
            className={classes.filterItemMenu}
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="Typ služeb"
            onClick={handleClickListItem}
          >
            <ListItemText
              primary={intl.formatMessage({ id: 'providerListServiceType' })}
              secondary={getServiceDesc(services[selectedService])}
            />
          </ListItem>
        </List>
        <Menu id="lock-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {services.map((option, index) => (
            <MenuItem
              className={classes.titleWithIcon}
              key={option.name}
              selected={index === selectedService}
              onClick={event => handleMenuItemClick(event, index)}
            >
              {getServiceDesc(option)}
            </MenuItem>
          ))}
        </Menu>
        {smallDevice &&
          (<IconButton aria-label="close" className={classes.closeButton} color="primary" onClick={() => {
            setHidden(!hidden);
          }}>
            {hidden ?
              <ArrowBackIosIcon /> : <ArrowForwardIosIcon />
            }
          </IconButton>
          )}
      </div>

      <div className={classes.root}>
        <Grid container spacing={3}>
          {listVisibleServices.map((provider, index) => (
            <Grid
              ref={element => {
                setRef(element, provider)
              }}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <div style={{ backgroundColor: '#fafafa' }}>
                <ProviderDetails provider={provider} executeScroll={executeScroll} updateFeatures={updateFeatures} />
              </div>
            </Grid>
          ))}
        </Grid>
        <img className={classes.eu} src={images['eu']} />
      </div>
    </div>
  )
}
