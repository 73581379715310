import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import images from '../../../global/images'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import NumberFormat from 'react-number-format'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { makeReservation } from '../../../axios/API'
import { useIntl } from 'react-intl'
import { Block } from '@material-ui/icons'
import CalendarIcon from '@material-ui/icons/EventAvailable'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  title: {
    backgroundColor: theme.palette.secondary.main,
    //backgroundColor: '#F0F0F0',
    color: theme.palette.common.black,
    lineHeight: '1.3',
    height: '60px',
    '& .MuiDialogTitle-root': {
      fontSize: 24,
      margin: '1px',
    },
    '& .MuiTypography-h6': {},
  },

  content: {
    minHeight: '200px',
    margin: '1px',
    padding: '20px',
  },
  contentCenter: {
    marginTop: '100px',
    textAlign: 'center',
  },
  create: {
    color: '#FFF',
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
      width: '25%',
    },
  },
  cancel: {
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
      width: '25%',
    },
    float: 'inline-start',
  },
  button: {
    width: '50%',
  },
  reservationSubTitle: {
    fontSize: 15,
    verticalAlign: 'middle',
    color: theme.palette.common.white,
  },
  reservationTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
    padding: 0,
    color: theme.palette.common.white,
  },
  time: {
    padding: 0,
    fontSize: 15,
    verticalAlign: 'middle',
    color: theme.palette.common.white,
    float: 'inline-end',
    fontWeight: 500,
  },
  end: {
    float: 'inline-end',
    fontWeight: 500,
  },
  icon: {
    width: 25,
    height: 25,
    display: 'none',
  },
  calendar: {
    width: 18,
    height: 18,
    margin: 2,
    float: 'inline-start',
  },
  divMenuProvider: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.blck,
  },
  divMenuService: {
    textAlign: 'center',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    display: 'block',
  },

  divMenuForm: {},
  divForm: {},
}))

const MAIN_MODE = 1
const INFO_MODE = 2
const ERROR_MODE = 3

export default function ReservationModal({ open, handleClose, workPlace, provider, appointment }) {
  const classes = useStyles()
  const [modalMode, setModalMode] = React.useState(MAIN_MODE)
  const intl = useIntl() // intl extracted from useIntl hook

  const [errors, setErrors] = React.useState({})

  const [values, setValues] = React.useState({
    name: '',
    email: '',
    phone: '',
  })

  const handleChange = value => event => {
    setValues({ ...values, [value]: event.target.value })
  }

  const validate = () => {
    let temp = {}
    let ret = false
    if ('name' in values) {
      temp.name = values.name && values.name.length > 0 ? '' : intl.formatMessage({ id: 'required' })
    }
    ret = temp.name && temp.name.length > 0
    if ('email' in values) {
      temp.email = values.email && values.email.length > 0 ? '' : intl.formatMessage({ id: 'required' })
      if (values.email && values.email.length) {
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email) ? '' : intl.formatMessage({ id: 'invalid_email' })
      }
    }
    ret = ret || (temp.email && temp.email.length > 0)

    if ('phone' in values) {
      temp.phone = values.phone && values.phone.length > 0 ? '' : intl.formatMessage({ id: 'required' })
    }

    ret = ret || (temp.phone && temp.phone.length > 0)

    setErrors({
      ...temp,
    })
    return ret
  }

  const { marketPrice, workplace, dateFrom } = workPlace
  const { address, name, phone } = provider
  const handleOrder = async () => {
    const ret = validate()
    if (ret) {
      return true
    }
    let data = { ...appointment }
    const { email, name, phone } = values
    data.email = email
    data.name = name
    data.phone = phone
    try {
      await makeReservation(`reservation?id=${appointment.appointmentId}`, data)
      setModalMode(INFO_MODE)
    } catch(error) {
      setModalMode(ERROR_MODE)
    }
  }
  const close = () => {
    let successful = modalMode === INFO_MODE || modalMode === ERROR_MODE;
    setModalMode(MAIN_MODE);
    handleClose(successful);
  }

  return (
    <Dialog open={open} onClose={() => close()} maxWidth="md" fullWidth={true}>
      {modalMode === MAIN_MODE && (
        <>
          <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
            <div className={classes.reservationSubTitle}>{intl.formatMessage({ id: 'create_reservation' })}</div>
            <div className={classes.reservationTitle}>
              {intl.formatMessage({ id: appointment.serviceName })} - {workplace}
              <span className={classes.end}>
                <NumberFormat value={marketPrice} displayType={'text'} decimalScale={0} /> Kč{' '}
                <img src={images[appointment.serviceName]} alt="" className={classes.icon} />
              </span>
            </div>
            <span className={classes.time}>
              {new Date(dateFrom).toLocaleTimeString()}
              <CalendarIcon fontSize="small" className={classes.calendar} />
            </span>
          </DialogTitle>

          <DialogContent className={classes.content}>
            <Grid container spacing={0}>
              <Grid item sm={6} spacing={1} className={classes.divMenuProvider}>
                <Typography variant="h6">{name}</Typography>
                <Typography variant="body1">
                  <b>{intl.formatMessage({ id: 'address' })}:</b> {address}
                </Typography>
                <Typography variant="body1">
                  <b>{intl.formatMessage({ id: 'phone' })}:</b> {phone}
                </Typography>
              </Grid>
              <Grid item sm={6} spacing={2} className={classes.divMenuForm}>
                <div className={classes.divForm}>
                  <TextField
                    required
                    id="standard-name"
                    label={intl.formatMessage({ id: 'name' })}
                    fullWidth
                    value={values.name}
                    onChange={handleChange('name')}
                    margin="dense"
                    helperText={errors.name}
                  />
                  <TextField
                    required
                    id="standard-email"
                    label={intl.formatMessage({ id: 'email' })}
                    type="email"
                    fullWidth
                    value={values.email}
                    onChange={handleChange('email')}
                    margin="dense"
                    helperText={errors.email}
                  />
                  <TextField
                    required
                    id="standard-telefon"
                    label={intl.formatMessage({ id: 'phone' })}
                    fullWidth
                    value={values.phone}
                    onChange={handleChange('phone')}
                    margin="dense"
                    helperText={errors.phone}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="default"
              onClick={close}
              startIcon={<CloseIcon />}
              className={classes.cancel}
            >
              {intl.formatMessage({ id: 'cancel' })}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOrder}
              startIcon={<CalendarIcon />}
              className={classes.create}
            >
              {intl.formatMessage({ id: 'create' })}
            </Button>
          </DialogActions>
        </>
      )}
      {modalMode === INFO_MODE && (
        <>
          <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
            <div className={classes.reservationSubTitle}>{intl.formatMessage({ id: 'create_reservation' })}</div>
            <div className={classes.reservationTitle}>
              {intl.formatMessage({ id: appointment.serviceName })} - {workplace}
              <span className={classes.end}>
                <NumberFormat value={marketPrice} displayType={'text'} decimalScale={0} /> Kč{' '}
                <img src={images[appointment.serviceName]} alt="" className={classes.icon} />
              </span>
            </div>
            <span className={classes.time}>
              {new Date(dateFrom).toLocaleTimeString()}
              <CalendarIcon fontSize="small" className={classes.calendar} />
            </span>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <DialogContentText className={classes.contentCenter}>
              {intl.formatMessage({ id: 'confirmation' })} <b>{' ' + values.email} </b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" className={classes.create} onClick={close}>
              OK
            </Button>
          </DialogActions>
        </>
      )}
      {modalMode === ERROR_MODE && (
        <>
          <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
            <div className={classes.reservationSubTitle}>{intl.formatMessage({ id: 'create_reservation' })}</div>
            <div className={classes.reservationTitle}>
              {intl.formatMessage({ id: appointment.serviceName })} - {workplace}
              <span className={classes.end}>
                <NumberFormat value={marketPrice} displayType={'text'} decimalScale={0} /> Kč{' '}
                <img src={images[appointment.serviceName]} alt="" className={classes.icon} />
              </span>
            </div>
            <span className={classes.time}>
              {new Date(dateFrom).toLocaleTimeString()}
              <CalendarIcon fontSize="small" className={classes.calendar} />
            </span>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <DialogContentText className={classes.contentCenter}>
              {intl.formatMessage({ id: 'rejection' })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" className={classes.create} onClick={close}>
              OK
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
