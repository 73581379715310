import React, { useEffect, useState } from 'react'
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents, Tooltip } from 'react-leaflet';
import LeafletgeoSearch from "./LeafletgeoSearch";
import "leaflet-geosearch/dist/geosearch.css";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForward';
import { IconButton } from "@material-ui/core";
import ProviderInfo from "../providerDetails/providerInfo";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import "./map.css";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        inset: '-5px 0px 0px',
        top: '70px',
        bottom: '0px',
    },
    aside: {
        height: '100vh',
        paddingTop: '80px',
        marginTop: '-80px',
        position: 'fixed',
        top: '70px',
        display: 'block',
        width: 'calc(100% - 50vw)'
    },
    asideFull: {
        height: '100vh',
        paddingTop: '80px',
        marginTop: '-80px',
        position: 'fixed',
        top: '70px',
        display: 'block',
        width: '100%',
    },
    map: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    divIcon: {
        width: '100%',
        height: '100%',
        whiteSpace: 'nowrap',
    },
    closeButton: {
        whiteSpace: 'nowrap',
        position: 'relative',
        marginLeft: '10px',
        marginTop: '48px',
        zIndex: 1000,
        width: '35px',
        height: '35px',
        color: 'black',
        backgroundColor: 'white',
    }
}));

function MapChanger({ mapInstance, providers, setMapVisibleServices, executeScroll }) {
    const [zoomLevel, setZoomLevel] = useState(5); // initial zoom level provided for MapContainer

    const getFeaturesInView = () => {
        var features = [];
        if (mapInstance) {
            try {
                mapInstance.eachLayer(function (layer) {
                    if (layer instanceof L.Marker) {
                        if (mapInstance.getBounds().contains(layer.getLatLng()) &&
                            layer.options.data) {
                            features.push(layer.options.data);
                        }
                    }
                });
            } catch (e) {
                console.log("Could not find features in view.", e);
            }
        }
        return features;
    }

    useEffect(() => {
        setMapVisibleServices(getFeaturesInView());
    }, [providers]);


    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
            setMapVisibleServices(getFeaturesInView());
        },
        moveend: () => {
            setMapVisibleServices(getFeaturesInView());
        },
        popupopen: (event) => {
            executeScroll(event.popup._source.options.data.id);
            console.warn(event.popup._source.options.data);
        }
    });

    return null
}

function Map({ providers, hidden, setHidden, setMapVisibleServices, executeScroll, scrolledOn }) {
    const classes = useStyles();

    const smallDevice = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [mapInstance, setMapInstance] = useState();


    function handleOnLocationFound(event) {
        const latlng = event.latlng;
        const radius = event.accuracy;
        mapInstance.flyTo(latlng, mapInstance.getZoom());
        const circle = L.circle(latlng, radius);

        circle.addTo(mapInstance);
    }


    /**
     * handleOnLocationError
     * @param {object} error Leaflet ErrorEvent object
     */

    function handleOnLocationError(error) {
        alert(`Unable to determine location: ${error.message}`);
    }

    useEffect(() => {
        if (mapInstance) {

            // Commenting this out to avoid blocked location requests
            // when embedded on the Egghead lesson page. To give this
            // a try, simply uncomment out the function call below

            mapInstance.locate({
                setView: true,
                enableHighAccuracy: true
            });

            mapInstance.on('locationfound', handleOnLocationFound);

            // Additional event handler for listening for
            // errors in finding someone's location

            mapInstance.on('locationerror', handleOnLocationError);

            return () => {
                mapInstance.off('locationfound', handleOnLocationFound);
                mapInstance.off('locationerror', handleOnLocationError);
            }
        }
    }, [mapInstance]);

    const fullSize = () => {
        setHidden(!hidden);
    };

    useEffect(() => {
        if (mapInstance)
            mapInstance.invalidateSize();
    }, [hidden, mapInstance]);

    useEffect(() => {
        if (scrolledOn && mapInstance) {
            const scrolledProv = providers.filter(e => e.id === scrolledOn.id)
            console.log("ZOOMING ON", scrolledProv, "ZOOM", mapInstance.getZoom())
            if (scrolledProv.length === 1) {
                mapInstance.flyTo(scrolledProv[0].geometry.coordinates, Math.max(mapInstance.getZoom(), 12))
            }
        }
    }, [scrolledOn]);

    return (
        <div className={classes.root} style={{
            position: 'absolute',
            width: hidden ? '100%' : 'calc(100% - 50vw)',
            left: hidden ? '0px' : 'auto',
            top: '70px',
        }}>
            <aside className={hidden ? classes.asideFull : classes.aside}>
                <MapContainer
                    whenCreated={mapInstance => {
                        setMapInstance(mapInstance)
                    }}
                    zoom={5}
                    className={classes.map}
                    maxZoom={18}
                    attributionControl={true}
                    zoomControl={true}
                    doubleClickZoom={true}
                    scrollWheelZoom={true}
                    dragging={true}
                    animate={true}
                    easeLinearity={0.35}

                >

                    <IconButton aria-label="close" className={classes.closeButton} color="primary" onClick={() => {
                        fullSize();
                    }}>
                        {hidden ^ !smallDevice ?
                            <ArrowBackIosIcon /> : <ArrowForwardIosIcon />
                        }
                    </IconButton>

                    <LeafletgeoSearch />

                    <TileLayer
                        url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> ©2019 RE4ME s.r.o. Reservago® je registrovanou známkou společnosti RE4ME s.r.o."
                    />
                    {providers.map((provider, idx) =>
                        <Marker key={`marker-${provider.id}`}
                            position={provider.geometry.coordinates}
                            data={provider} // <-- get this value in onClick handler
                        >
                            <Popup>
                                <Typography variant="h5">{provider.properties.name}</Typography>

                                <ProviderInfo address={provider.properties.address}
                                    phone={provider.properties.phone}
                                />
                            </Popup>
                            <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>{`${provider.properties.appointments[0].marketPrice} Kč`}</Tooltip>
                        </Marker>
                    )}
                    <MapChanger executeScroll={executeScroll} providers={providers} mapInstance={mapInstance}
                        setMapVisibleServices={setMapVisibleServices} />

                </MapContainer>
            </aside>
        </div>
    );
}

export default Map
