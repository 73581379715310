import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import ProviderInfo from './providerInfo'
import Card from '@material-ui/core/Card'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { IconButton } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import ReservationModal from './modal/reservationModal'
import images from '../../global/images'
import { useIntl } from 'react-intl'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  card: {
    minHeight: '260px',
    height: '100%',
    width: '100%',
    borderRadius: '10px',
  },
  icon: {
    width: '22px',
    height: '22px',
    paddingRight: '10px',
  },
  titleWithIcon: {
    padding: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100px',
  },
  providerHeader: {
  },
  imageListroot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  branchImage: {
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  }
}))

const SHOWN_PLACES = 2
const SHOWN_PLACES_MAX = 100

const StyledTableCell = withStyles(theme =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

const StyledTableRow = withStyles(theme =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      cursor: 'pointer',
    },
  })
)(TableRow)

export default function ProviderDetails({ provider, executeScroll, updateFeatures }) {
  const classes = useStyles()
  const intl = useIntl() // intl extracted from useIntl hook
  const [open, setOpen] = useState(false)
  const [workPlace, setWorkPlace] = useState({})
  const [workplaces, setWorkplaces] = useState([])
  const [showMore, setShowMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (provider) {
      const workplacesInner = []
      let i = 0
      let max = showMore ? SHOWN_PLACES_MAX : SHOWN_PLACES
      for (; i < provider.properties.appointments.length && i < max; i++) {
        workplacesInner.push({
          workplaceId: provider.properties.appointments[i].workplaceId,
          workplaceName: provider.properties.appointments[i].workplaceName,
          serviceId: provider.properties.appointments[i].serviceId,
          serviceName: provider.properties.appointments[i].serviceName,
        });
      }
      if (i > SHOWN_PLACES) {
        setHasMore(true)
      }
      setWorkplaces(workplacesInner)
    }
  }, [provider, showMore])


  const handleOpen = row => {
    setWorkPlace(row)
    setOpen(true)
  }

  const handleClose = successful => {
    console.log("handleClose = successful", successful);
    if (successful)
      updateFeatures();
    setOpen(false);
  }

  const getServiceDesc = workplace => {
    return (
      <div className={classes.titleWithIcon}>
        {workplace && <img className={classes.icon} src={/*images[workplace.service] TODO*/images.all} />}
        <div className={classes.serviceName}> {workplace && workplace.serviceName}</div>
      </div>
    )
  }

  if (!provider) {
    return null
  }

  console.log('SERVICES', provider.properties)

  return (
    <Card className={classes.card}>
      <ReservationModal
        open={open}
        handleClose={handleClose}
        workPlace={workPlace}
        appointment={provider.properties.appointments[0]}
        provider={provider.properties}
      />

      {provider.properties.photos.length > 0 &&
        (
          <GridList className={classes.imageList} cols={smallDevice ? 1.5 : 4.5}>
            {provider.properties.photos.map((item, index) => (
              <GridListTile key={item.img}>
                <img
                  src={`data:image/jpeg;base64,${item.imageContentPreview}`}
                  alt={`${provider.properties.name}-${index}`}
                  className={classes.branchImage} />
              </GridListTile>
            ))}
          </GridList>)
      }

      <CardHeader
        title={provider.properties.name}
        subheader={<ProviderInfo
          address={provider.properties.address}
          phone={provider.properties.phone}
          mapClick={() => executeScroll(provider.id)} />}
        className={classes.providerHeader}
      ></CardHeader>

      <CardContent>
        <Table className={classes.table} aria-label="table">
          <TableHead>
            <TableRow>
              {smallDevice && (
                <>
                  <StyledTableCell align="left">{intl.formatMessage({ id: 'service' })}
                    <Divider className={classes.divider}/>{intl.formatMessage({ id: 'workplace' })}</StyledTableCell>
                  <StyledTableCell align="left">{intl.formatMessage({ id: 'date' })}<Divider className={classes.divider} />{intl.formatMessage({ id: 'price' })}</StyledTableCell>
                </>)}
              {!smallDevice && (
                <>
                  <StyledTableCell align="left">{intl.formatMessage({ id: 'service' })}</StyledTableCell>
                  <StyledTableCell align="left">{intl.formatMessage({ id: 'workplace' })}</StyledTableCell>
                  <StyledTableCell align="left">{intl.formatMessage({ id: 'date' })}</StyledTableCell>
                  <StyledTableCell align="left">{intl.formatMessage({ id: 'price' })}</StyledTableCell>
                </>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {provider.properties.appointments.map(row => (
              <StyledTableRow
                key={row.workplaceName}
                onClick={() => {
                  handleOpen(row)
                }}
              >

                {smallDevice && (
                  <>
                    <StyledTableCell align="left">{getServiceDesc(row)}<Divider className={classes.divider} />{row.workplaceName}</StyledTableCell>
                    <StyledTableCell align="left">{row.dateFrom}<Divider className={classes.divider} /><b>
                      <NumberFormat value={row.marketPrice} displayType={'text'} decimalScale={0} /> Kč
                    </b></StyledTableCell>
                  </>)}
                {!smallDevice && (
                  <>
                    <StyledTableCell align="left">{getServiceDesc(row)}</StyledTableCell>
                    <StyledTableCell align="left">{row.workplaceName}</StyledTableCell>
                    <StyledTableCell align="left">{new Date(row.dateFrom).toLocaleTimeString()}</StyledTableCell>
                    <StyledTableCell align="left">
                      <b>
                        <NumberFormat value={row.marketPrice} displayType={'text'} decimalScale={0} /> Kč
                      </b>
                    </StyledTableCell>
                  </>)}
              </StyledTableRow>
            ))}
          </TableBody>
          {hasMore && (
            <TableFooter>
              <IconButton aria-label="expand" onClick={() => setShowMore(!showMore)}>
                {showMore ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
              </IconButton>
            </TableFooter>
          )}
        </Table>
      </CardContent>
    </Card >
  )
}
