import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import RoomIcon from '@material-ui/icons/Room';

export default function ProviderInfo({ address, phone, mapClick }) {
  const intl = useIntl() // intl extracted from useIntl hook

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">
          <b>{intl.formatMessage({ id: 'address' })}:</b> {address} 
          <RoomIcon onClick={mapClick} style={{ cursor: "pointer" }} />
        </Typography>
        <Typography variant="body1">
          <b>{intl.formatMessage({ id: 'phone' })}:</b> {phone}
        </Typography>
      </Grid>
    </Grid>
  )
}
