import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Map from '../map/Map';
import ReservagoAppBar from '../bar/bar';
import ProviderList from '../providerList';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'block',
    minHeight: '100vh',
  },
  main: {
    display: 'block',
  },
  wrapper: {
    display: 'block',
    inset: '105px 0px 0px',
  },
  wrapperHeader: {
    display: 'block',
    height: '80px',
  },
}))

const ReservationContainer = ({
  selectedService,
  services,
  providers,
  setSelectedService,
  appLanguage,
  setAppLanguage,
  updateFeatures,
}) => {
  const classes = useStyles();

  const smallDevice = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [hidden, setHidden] = useState(false);
  const [mapVisibleServices, setMapVisibleServices] = useState([]);
  const [listVisibleServices, setListVisibleServices] = useState([]);
  const [scrolledOn, setScrolledOn] = useState(null);

  useEffect(() => {
    setHidden(smallDevice);
  }, [smallDevice])

  const executeScroll = id => {
    setScrolledOn({ id: id });
    if (smallDevice)
      setHidden(!hidden);
    else
      setHidden(false);
  }

  const updateFeaturesInner = (success) => {
    if (smallDevice) // On small device
      setHidden(true);
    updateFeatures(success);
  }

  const setMapVisibleServicesInner = (s) => {
    if((!smallDevice || hidden)) // Map is visible
      setMapVisibleServices(s);
  };

  useEffect(() => {
    if (smallDevice && !hidden) {// Map not visible
      // Do manual filtering if the map is not visible
      // hence no additional map filtering cannot be done.
      setListVisibleServices(mapVisibleServices.filter((mapService) => providers.some(provider => provider.id === mapService.id)));
    } else {
      setListVisibleServices(mapVisibleServices);
    }
  }, [providers, mapVisibleServices]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapperHeader}>
        <ReservagoAppBar />
      </div>
      <main className={classes.main}>
        <div className={classes.wrapper}>
          <div style={{ display: !hidden ? "block" : "none" }}>
            <ProviderList
              scrolledOn={scrolledOn}
              selectedService={selectedService}
              services={services}
              setSelectedService={setSelectedService}
              listVisibleServices={listVisibleServices}
              executeScroll={executeScroll}
              updateFeatures={updateFeaturesInner}
              hidden={hidden}
              setHidden={setHidden}
            />
          </div>
          <div style={{ display: (!smallDevice || hidden) ? "block" : "none" }}>
            <Map
              providers={providers}
              hidden={hidden}
              setHidden={setHidden}
              scrolledOn={scrolledOn}
              executeScroll={executeScroll}
              setMapVisibleServices={setMapVisibleServicesInner}
            />
          </div>
        </div>
      </main>
    </div>
  )
}

export default ReservationContainer
