import barbershop from "../sources/barbershop.jpg";
import hairstylist from "../sources/hairstylist.png";
import sauna from "../sources/sauna.png";
import logo from "../sources/reservago-logo.png";
import eu from "../sources/logolink-OP-PPR.jpg";
import all from "../sources/all.png";

const images = {
  barbershop,
  hairstylist,
  sauna,
  logo,
  eu,
  all,
}


export default images;
