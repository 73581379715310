import React, { useEffect, useState } from "react";
import ReservationContainer from "./components/reservationContainer";
import { requestEndPoint } from "./axios/API";
import images from "./global/images";
import { useIntl } from "react-intl";


function AppWrapper() {

    const [ready, setReady] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [providers, setProviders] = useState([]);
    const [filteredProviders, setFilteredProviders] = useState([]);
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(0);
    const intl = useIntl(); // intl extracted from useIntl hook


    useEffect(() => {
        if (providers) {
            const servicesInner = [{ name: intl.formatMessage({ id: 'all' }), icon: images['all'] }];
            const servicesNames = new Set()
            for (let j = 0; j < providers.length; j++) {
                if (providers[j]) {
                    for (let h = 0; h < providers[j].properties.appointments.length; h++) {
                        const sname = providers[j].properties.appointments[h].serviceName;
                        if (!servicesNames.has(sname)) {
                            servicesNames.add(sname);
                            servicesInner.push({ name: sname });
                        }
                    }
                }
            }
            setServices(servicesInner);
            filter();
        }
    }, [providers]);


    useEffect(() => {
        filter();
    }, [selectedService, services]);


    const filter = () => {
        const filteredProvidersInner = [];
        for (let j = 0; j < providers.length; j++) {
            if (providers[j]) {
                const service = services[selectedService];
                let matched = false;
                for (let h = 0; h < providers[j].properties.appointments.length; h++) {
                    if (providers[j].properties.appointments[h].serviceName === service.name || selectedService === 0) {
                        matched = true;
                    }
                }
                if (matched) {
                    filteredProvidersInner.push(providers[j]);
                }
            }

        }
        setFilteredProviders(filteredProvidersInner);
    }


    useEffect(() => {
        requestEndPoint("features").then((providers) => {
            setSelectedService(0);
            setProviders(providers);
            setReady(true);
            setShouldUpdate(false);
        }
        );
    }, [shouldUpdate]);

    const updateFeatures = () => { 
        console.log("UPDATING FEATURES");
        setShouldUpdate(true); 
    }

    return (
        ready ?
            <ReservationContainer
                selectedService={selectedService}
                services={services}
                setSelectedService={setSelectedService}
                providers={filteredProviders}
                updateFeatures={updateFeatures}
            />
            :
            null);

}

export default AppWrapper;
