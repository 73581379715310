import axios from 'axios'
const username = 'webapi'
const password = '2018api'
const basicAuth = 'Basic ' + btoa(username + ':' + password)

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: { Authorization: basicAuth },
})

export const requestEndPoint = async arg => {
  const { data: data } = await instance.get(arg)
  return data.features
}

export const makeReservation = async (arg, post) => {
  const { data: data } = await instance.post(arg, post)
  return data
}
