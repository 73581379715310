import React, {useState} from "react";
import "./App.css";
import {MuiThemeProvider} from "@material-ui/core/styles";
import theme from "./muiTheme/MuiTheme";
import {IntlProvider} from "react-intl";
import Czech from './translations/cs.json';
import English from './translations/en.json';
import AppWrapper from "./AppWrapper";

export const Context = React.createContext();


let local = navigator.language.substring(0, 2);
let lang;
if (local === 'cs') {
    lang = Czech;
} else {
    local = 'en'; //default
    lang = English;
}

function App() {


    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLanguage(value) {
        const newLocale = value;
        setLocale(newLocale);
        if (newLocale === 'cs') {
            setMessages(Czech);
        } else {
            setMessages(English);
        }
    }


    return (
        <MuiThemeProvider theme={theme}>
            <Context.Provider value={{locale, selectLanguage}}>
                <IntlProvider messages={messages} locale={locale}>
                    <AppWrapper/>
                </IntlProvider>
            </Context.Provider>
        </MuiThemeProvider>
    );

}

export default App;
