import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: "#fafafa"
    },
    secondary: { main: "#F48F02" },
    hazard: { main: "#50A732" },
	background:  {paper: "#FFFFFF"}
  }
});
